/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "./../App.css";
import FormSelect from "./FormSelect";
import SignerTable from "./SignerTable";
import Confirmation from "./Confirmation";
import { isIE } from 'react-device-detect';
import EnvelopeStatus from "./../EnvelopeStatus";
import CIAMRedirect from "./CIAMRedirect";
import { UtilityHeader, H1, MessageCard, Modal, Stack, Button, H3, P } from "@manulife/mux";
import * as CDS from '@manulife/mux-cds-icons';
import { loadInfo } from "../actions/formSelectActions";
import { bffRefreshSession, bffRefreshSessionInProgress, bffLogoutSession } from "../actions/CIAMActions";
import localizationObject from "../localization";
import { connect } from "react-redux";
import qs from "qs";
import { useSelector } from "react-redux";

// class Routes extends Component {
const Routes = (props) => {
  const reactPath = window.REACT_APP_PUBLIC_URL;
  const redirectURL = window.REACT_APP_CIAMBFF_URL;
  const OK_RESPONSE = 200;
  const UNATHORIZED_RESPONSE = 403;
  const SERVICE_UNAVAILABLE = 503;
  const maxRetryConnect = 3;
  let retryCounter = 0;
  const ciamSessionRefreshCoolDown = parseInt(window.REACT_APP_SESSION_REFRESH_COOLDOWN_SEC);
  const ciamSessionRefreshCoolDownMin = ciamSessionRefreshCoolDown / 60;
  const [isModalOpen, setModalOpen] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const [timebeforePopup, setTimebeforePopup] = useState(Date.now());
  const [lastCiamRefresh, setLastCiamRefresh] = useState(false);
  const [timeNow, setTimeNow] = useState(Date.now());
  const [loggedout, setLoggedout] = useState(false);
  const [counter, setCounter] = useState(0);
  const [startTimer, setStartTimer] = useState(false);
  const [logoutM, setLogoutMessage] = useState(false);
  const language = useSelector(state => state.pageLoad.language);
  const [localization, setLocalization] = useState({});
  const [timeLeft, setTimeLeft] = useState({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  const [timeToShow, setTimeToShow] = useState(Date.now()); // the time shown on sessiontimeout popup

  let state = {
    location: "",
    formnumber: "",
    token: ""
  };

  // 0 - addEventlistener and set initial time (will render only ONCE)
  useEffect(() => {
    // Set initial active time
    setLastActiveTime(minutesIdleBeforeSessionExpiration());
    setTimebeforePopup(minutesIdleBeforeWarning());
    setTimeToShow(minutesIdleBeforeSessionExpiration());
    calculateTimeLeft(minutesIdleBeforeSessionExpiration());
  }, []);

  // translation of french/english
  useEffect(() => {
    if (language.length > 0) {
      setLocalization(localizationObject[language]);
    }
  }, [language]);

  // refresh on action here, remove refresh on popup
  useEffect(() => {
    if(isModalOpen || logoutM)
    {
      document.removeEventListener('mousemove',refreshSession.current);
      document.removeEventListener('keydown',refreshSession.current);
    }
    else if (isModalOpen == false && logoutM == false)
    {
      document.addEventListener('mousemove',refreshSession.current);
      document.addEventListener('keydown',refreshSession.current);
    }
  }, [isModalOpen, logoutM]);

  // session timeout popup open - also timeout logout popup
  useEffect(() => {
    if (timeNow > timebeforePopup && timeNow < lastActiveTime && !loggedout) {
      setStartTimer(true);
      openModal();
      setTimebeforePopup(Date.now());
    }  else if (timeNow > lastActiveTime) {
      // setLoggedout(true);
      // If the time now is {30} minutes more than the last active time
      // Set the last Active time to blank when logging out
      setLastActiveTime(Date.now());
      // Logout the user
      setLoggedout(true);
      userLogout();
      setLogoutMessage(true); // open sign-out modal
    } else if (startTimer == false && timeNow < timebeforePopup) {
      // reset the setTimeToShow when warning modal is closed
      setTimeToShow(lastActiveTime);
    }
    // Refresh Ciam Session 
    checkBffRefreshSessionProgress();

    // Reset to false to check again when user is idle
    setLastCiamRefresh(false);
  }, [timeNow, lastActiveTime, timebeforePopup]);

  // time calculation for timetoshow
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter+1);
      if (startTimer) { // the number that is shown in popup 
        setTimeLeft(calculateTimeLeft(timeToShow));
      }

      // compute only every 30 seconds
      if (counter > 0 && counter % 30 == 0) {
        // compute time diff between active and idle
        const timeDiff = calculateDateDiff(Date.now(), timeNow);

        // check after 1 minute of idle
        if (timeDiff.minutes >= 1 && lastCiamRefresh === false) {

          // check if {ciamSessionRefreshCoolDownMin} minute/s has passed
          // to avoid call the refreshCiam prematurely
          const timeDiffLastRef = calculateDateDiff(Date.now(), props.refreshedCIAMSessionTime);

          // DEBUGGING, on DEV/UAT ONLY
          if (window.REACT_APP_ENV == "DEV" 
              || window.REACT_APP_ENV == "UAT") {
            console.log(counter);
            // After 1 minute of idle refreshSession one more time (for the last time) 
            // before user goes back to the page
            console.log(`${timeDiff.minutes} minute/s has passed since idle.`, timeDiff, timeDiffLastRef);
          }
          
          if (timeDiffLastRef.minutes >= ciamSessionRefreshCoolDownMin) {
            console.log("REFRESH LAST TIME: ", timeDiffLastRef);
            // Set this to true to prevent multiple calls
            setLastCiamRefresh(true);
            // call refreshSession for the last time
            checkBffRefreshSessionProgress();
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    }; 
  },[counter]);



  // session timeout popup expired - logout popup
  useEffect(() => {
    if (startTimer && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
      closeModal();
      setLogoutMessage(true); // open sign-out modal
      setTimeout(()=>{userLogout();},1000); // sign-out after 5 seconds
    }
  },[timeLeft]);

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setStartTimer(false);
    setModalOpen(false);
  }

  const resetSession = () => {
    setTimeLeft({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    closeModal();
  }

    /**
   * refreshSession = resets session time-out timer if user moves mouse/presses key
   * wrapped in useRef() incase of rerender so its consistent which is needed to removeEventlistener
   */
  const refreshSession = useRef(() => {
    // Re-set the last active time when user moves around the page again
    setTimeNow(Date.now());
    setLastActiveTime(minutesIdleBeforeSessionExpiration());
    setTimebeforePopup(minutesIdleBeforeWarning()); //calculates time in popup
  })

  const checkBffRefreshSessionProgress = async () =>  {

    if (!props.logoutInProgress) {
        if (props.ciamSession) {
          if (props.refreshedCIAMSessionTime + 30000 <= Date.now()) {
            await ciamBffSessionRefresh();
          }
        }
      }
  }

  const ciamBffSessionRefresh = async () => {
    // refreshSession if modal is not open
    if (!props.ciamREFRESHInProgress && isModalOpen == false) {
      await props.bffRefreshSessionInProgress()
      const bffRefreshedStatus = 
        await props.bffRefreshSession();
      if (bffRefreshedStatus === OK_RESPONSE) {
        // reset retry counter
        retryCounter = 0;
        return;
      }
      if (bffRefreshedStatus === UNATHORIZED_RESPONSE) {
        // handle logout if ciam session is already expired
        setLoggedout(true);
        userLogout();
        setLogoutMessage(true); // open sign-out modal
      } else if (bffRefreshedStatus != OK_RESPONSE ) {
          // For now if 503
          // retry
        if (retryCounter < maxRetryConnect) {
          retryCounter++;
          ciamBffSessionRefresh();
          return;
        }
      }
    }
  }
  
  /**
   * Get computed time for now plus 30 minutes or whichever is set in REACT_APP_SESSION_EXPIRATION_MINUTES
   * 
   * @returns number
   */
   const minutesIdleBeforeSessionExpiration = () => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + parseInt(window.REACT_APP_SESSION_EXPIRATION_MINUTES));
    return date.getTime();
  }

  /**
   * Get computed time, {minute/s} before session expiration 
   * if called with "show" will use other computation
   * did it this way to avoid making another function
   * @returns number
   */
  const minutesIdleBeforeWarning = (a) => {
    if(a === "show")
    {
      let date = new Date(Date.now());
      let timeBeforeExp = parseInt(window.REACT_APP_SESSION_BEFORE_EXPIRATION_MINUTES);
      date.setMinutes(date.getMinutes() + timeBeforeExp);
      return date.getTime();
    }
    else{
      let date = new Date(Date.now());
      let timeBeforeExp = parseInt(window.REACT_APP_SESSION_EXPIRATION_MINUTES) - parseInt(window.REACT_APP_SESSION_BEFORE_EXPIRATION_MINUTES);
      date.setMinutes(date.getMinutes() + timeBeforeExp);
      return date.getTime();
    }
  }
  /**
   * Compute time left
   * 
   * @returns object
   */
  const calculateTimeLeft = (dateFutureMilliseconds) => {
    let dateNow = Date.now();
    let timeLeft = {};

    timeLeft = calculateDateDiff(dateFutureMilliseconds, dateNow);

    return timeLeft;
  };

  const calculateDateDiff = (dateFutureMilliseconds, datePastMilliseconds) => {
    let timeDiff = {};

    // If date in past is greater than the future date then return 0
    if (datePastMilliseconds > dateFutureMilliseconds) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    let seconds = Math.floor((dateFutureMilliseconds - datePastMilliseconds)/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);


    timeDiff = {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };

    return timeDiff;
  }

  /**
   * Logout user and sets the logoutInProgress to true
   * pop up logged out modal then call userLogout()
   */
  const userLogout = async () =>  {
    setTimeout(()=>{setLogoutMessage(true);},1000); // sign-out after 5 seconds
    if (props.ciamSession) {
      await props.bffLogoutSession(props.routeState.formnumber);
    }
  }

  const getRoute = async (props) => {
    let query = qs.parse(props.location.search)["?access_token"];
    if (query !== undefined) {
      let FN = query.slice(query.indexOf("|") + 1, query.length);
      state.formnumber = FN.slice(FN.indexOf("=") + 1, FN.length);
      state.token = query.slice(0, query.indexOf("|"));
      if (this.state.formnumber === "" || state.formnumber === undefined) {
        state.location = "./EnvelopeStatus";
      } else {
        state.location = "./";
      }
    }
  }
  // render
  if (isIE) return (
      <div>
        <MessageCard
          hasCloseButton={false}
          closeIconAriaLabel={localizationObject.en.IEIncompatibilityNotice}
          variant={MessageCard.VARIANT.PRIMARY_ALERT}
        >
          {localizationObject.en.IEIncompatibilityNotice}
        </MessageCard>
        <MessageCard
          hasCloseButton={false}
          variant={MessageCard.VARIANT.PRIMARY_ALERT}
          closeIconAriaLabel={localizationObject.fr.IEIncompatibilityNotice}
        >
          {localizationObject.fr.IEIncompatibilityNotice}
        </MessageCard>
      </div>)
    return (
      <Router basename={reactPath}>
        <div className="App">
          <Switch>
            <div>
              <Stack>
                <Modal isOpen={isModalOpen} onClose={()=> closeModal()} showCloseButton={false} shouldCloseOnBackdropClick={false} ariaLabel="s">
                  <Stack>
                    <H3>
                      <div>{localization != '' ? localization.SessionTimeout:"Session Timeout"}</div>
                    </H3>
                    <P>
                      {localization != '' ? localization.SessionTimeoutMessage:"Your session is about to expire, incomplete eforms will be lost."}
                    </P>
                    <CDS.Clock title="Clock" />
                    <P>
                      <>{timeLeft.minutes}:{timeLeft.seconds}</>
                    </P>
                    <Button onClick={()=> resetSession()} ariaLabel={localization != '' ? localization.Reset:"Reset"}>
                      <div>{localization != '' ? localization.Reset:"Reset"}</div>
                    </Button>
                  </Stack>
                </Modal>
                <Modal isOpen={logoutM} showCloseButton={false} shouldCloseOnBackdropClick={false}>
                  <Stack>
                    <H3>
                      <div>{localization != '' ? localization.SignOut:"Sign Out"}</div>
                    </H3>
                    <P>
                      <>{localization != '' ? localization.SignOutMessage:"You have successfully signed out"}</>
                    </P>
                  </Stack>
                </Modal>
              </Stack>
              <div className="header">
                  {<span>&nbsp;</span>}
                  <UtilityHeader headerTabs=
                  {
                    [
                      {id: "header", label: (<H1 color="white">eForms</H1>), value:""}
                    ]
                  }>
                      {props.ciamSession ? [
                      <UtilityHeader.HeaderButton
                      key="signout"
                      id="Sign out"
                      label={<b color="white">{localization != '' ? localization.SignOut:"Sign Out"}</b>}
                      onClick={() => {
                        userLogout();
                      }}
                    />
                      ]:[]}
                    </UtilityHeader>
              </div>
              <div className="pageContent">
                  <Route path="/" exact component={FormSelect} />
                  <Route path="/EnvelopeStatus" exact component={EnvelopeStatus} />
                  <Route path="/Signers" exact component={SignerTable} />
                  <Route path="/Confirmation" exact component={Confirmation} />
                  <Route
                    path="/callback"
                    render={props => {
                      getRoute(props);
                      props.loadInfo(this.state);
                      return (
                        <Redirect
                          to={{
                            pathname: state.location
                          }}
                        />
                      );
                    }}
                  />
                  <Route path="/ciamRedirect" exact component={CIAMRedirect} />
              </div>
            </div>
          </Switch>
        </div>
      </Router>
    );

}
const mapStateToProps = state => ({
  routeState: state.pageLoad.routeState,
  refreshedCIAMSessionTime: state.pageLoad.refreshedCIAMSessionTime,
  ciamREFRESHInProgress: state.pageLoad.ciamREFRESHInProgress,
  logoutInProgress: state.pageLoad.logoutInProgress,
  ciamSession: state.pageLoad.ciamSession,
  ciamSessionRef: state.pageLoad.ciamSessionRef
});

export default connect(
  mapStateToProps,
  { loadInfo, bffRefreshSession, bffRefreshSessionInProgress, bffLogoutSession }
)(Routes);
