/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import axios from "axios";

const getRowsRoute = window.REACT_APP_GETROWS_ROUTE;
const MGURL = window.REACT_APP_BFF_MG_URL;

export async function search(e, forms, setformNumberAndName, setTable) {
  setformNumberAndName(e);
  const tempTable = [];
  forms.map(record => {
    if (record.FormNumberAndName.toUpperCase().includes(e.toUpperCase())) {
      tempTable.push(record);
    }
  });
  setTable(tempTable);
}

export const getRoleData = (formnumber, language) => {
  return axios
    .post(getRowsRoute, {
      data: {
        FormNumber: formnumber,
        Language: language
      }
    })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};
