/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
import { GET_ROLES } from "../actions/types";

const initialState = {
  templateID: "",
  accountId: "",
  formInfo: "",
  deliveryMethod: {},
  signerRoles: [],
  advisorPrefill: {},
  carbonCopies: []
};

const rolesReducer = (state = initialState, action = null) => {
  if (action.type === GET_ROLES) {
    const result = action.data;
    const { deliveryMethod, advisor, roles, carbonCopy } = formatRoles(result);
    const deliveryMethodValues = Object.values(deliveryMethod);
    const deliveryMethods = {};
    const tempRoles = [advisor, ...roles, ...carbonCopy];
    let count = 0;
    deliveryMethodValues.forEach(item => {
      if (count === 0) {
        deliveryMethods[tempRoles[count].name] = item;
      } else {
        const newKey = tempRoles[count].name;
        deliveryMethods[newKey] = item;
      }
      count += 1;
    });
    return {
      ...state,
      templateID: result.templateId,
      accountId: result.accountId,
      formInfo: result.formInfo,
      signerRoles: roles,
      advisorPrefill: advisor,
      carbonCopies: carbonCopy,
      deliveryMethod
    };
  }
  return state;
};

const formatRoles = result => {
  const roles = [];
  const carbonCopy = [];
  let advisor = {};
  const deliveryMethod = {};
  for (const key in result.advisers) {
    if (result.advisers.hasOwnProperty(key)) {
      const advisers = result.advisers[key];
      for (let i = 0; i < advisers.length; i++) {
        const { roleName } = advisers[i];
        advisor = assignValues(
          roleName,
          key,
          deliveryMethod,
          advisor,
          roles,
          carbonCopy
        );
      }
    }
  }
  return { deliveryMethod, advisor, roles, carbonCopy };
};

const assignValues = (
  roleName,
  key,
  paramDeliveryMethod,
  paramAdvisor,
  roles,
  carbonCopy
) => {
  const advisorRole = {
    roleName,
    name: "",
    email: ""
  };
  let advisor = paramAdvisor;
  const deliveryMethod = paramDeliveryMethod;
  if (key === "signers") {
    deliveryMethod[roleName] = true;
    if (roleName.includes("Advisor Optional Prefill")) {
      advisor = advisorRole;
    }
    if (!roleName.includes("Advisor Optional Prefill")) {
      roles.push(advisorRole);
    }
  } else if (key === "carbonCopies") {
    carbonCopy.push(advisorRole);
  }
  return advisor;
};

export default rolesReducer;
