import { combineReducers } from "redux";
import envelopeReducer from "./envelopeReducer";
import pageLoadReducer from "./pageLoadReducer";
import rolesReducer from "./rolesReducer";
import statusReducer from "./statusReducer";

export default combineReducers({
  envelope: envelopeReducer,
  pageLoad: pageLoadReducer,
  roles: rolesReducer,
  status: statusReducer
});
