import { RESPONSE, LOAD_INFO, DISMISS_WARNING, SET_LANGUAGE } from "./types";

export const responed = (message, disabled, passed) => {
  return {
    type: RESPONSE,
    response: message,
    disabled,
    passed
  };
};

export const loadInfo = routeState => {
  return {
    type: LOAD_INFO,
    routeState
  };
};

export const dismissWarning = flag => {
  return {
    type: DISMISS_WARNING,
    dismissWarning: flag
  };
};

export const storeLanguage = strings => {
  return {
    type: SET_LANGUAGE,
    strings
  };
};
