/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import axios from "axios";
import { CREATE_ENVELOPE, RESPONSE, NEXT, STORE_URL } from "./types";

const insertDBRoute = window.REACT_APP_INSERTDATA_ROUTE;
const getDocURL = window.REACT_APP_PREFILL_ROUTE;

export const insertCustomer = (
  tempEnvelopeState,
  tempPageLoadState,
  tempRolesState,
  ownerFirstName,
  ownerLastName,
  envelopeID,
  accessCode
) => (dispatch) => {
  return axios
    .post(insertDBRoute, {
      data: {
        policyNumber: tempEnvelopeState.policyNumber,
        firstName: ownerFirstName,
        lastName: ownerLastName,
        repsourceUserID: tempPageLoadState.RepsourceID,
        envelopeId: envelopeID,
        accountId: tempRolesState.accountId
      }
    })
    .then(() => {
      dispatch({
        type: CREATE_ENVELOPE,
        response: `${tempPageLoadState.strings.SuccessMessage} ${accessCode}`,
        disabled: true,
        passed: true,
        envelopeId: envelopeID
      });
    })
    .catch(() => {
      dispatch({
        type: CREATE_ENVELOPE,
        response: tempPageLoadState.strings.ServerError,
        sendDisabled: true,
        disabled: true,
        passed: false
      });
    });
};

export const nextSigner = (
  accountID,
  email,
  envelopeID,
  name,
  strings,
  userCounter
) => (dispatch) => {
  return axios
    .post(getDocURL, {
      data: {
        accountId: accountID,
        authenticationMethod: "email",
        clientUserId: "9999",
        email,
        envelopeId: envelopeID,
        userName: name
      }
    })
    .then((response) => {
      ++userCounter;
      const nextUser = userCounter;
      dispatch({
        type: NEXT,
        userCounter: nextUser,
        nextSignerURL: response.data.url
      });
    })
    .catch(() => {
      dispatch({
        type: RESPONSE,
        response: strings.ServerError,
        sendDisabled: true,
        disabled: true,
        passed: false
      });
    });
};

export const storeURL = (docuSignURL) => {
  return {
    type: STORE_URL,
    docuSignURL
  };
};
