/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import qs from "qs";
import localizationObject from "../localization";

import { bffSessionInfo } from "../actions/CIAMActions";

function CIAMRedirect() {
  const dispatch = useDispatch();
  const [englishError, setEnglishError] = useState("");
  const [frenchError, setFrenchError] = useState("");
  const { search } = useLocation();
  const SessionInfoHandler = sessionInfo =>
    dispatch(bffSessionInfo(sessionInfo));

  const history = useHistory();

  const initialState = useSelector(state => state.pageLoad.routeState);

  useEffect(() => {
    const query = qs.parse(search, [search]);
    if (query["?error"]) {
      setEnglishError(
        `${localizationObject.en.CIAMRedirectError} ${query["?error"]}`
      );
      setFrenchError(
        `${localizationObject.fr.CIAMRedirectError} ${query["?error"]}`
      );
    } else {
      async function getSessionInfo() {
        try {
          const response = await fetch(
            `${window.REACT_APP_CIAMBFF_URL}/sessionInfo`,
            {
              method: "GET",
              credentials: "include"
            }
          );
          const sessionData = await response.json();
          SessionInfoHandler(sessionData);
        } catch (error) {
          setEnglishError(`${localizationObject.en.CIAMRedirectError}`);
          setFrenchError(`${localizationObject.fr.CIAMRedirectError}`);
        }
      }
      getSessionInfo();
    }
  }, []);

  useEffect(() => {
    if (initialState.hasOwnProperty("token") && englishError === "") {
      if (initialState.formnumber) {
        history.push("/");
      } else {
        history.push("/EnvelopeStatus");
      }
    }
  }, [initialState]);

  return (
    <div>
      {englishError === "" ? (
        "Loading..."
      ) : (
        <div>
          <p>{englishError}</p>
          <p>{frenchError}</p>
        </div>
      )}
    </div>
  );
}

export default CIAMRedirect;
