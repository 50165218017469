export const GET_ROLES = "GET_ROLES";
export const LOAD_PAGE = "LOAD_PAGE";
export const LOAD_STATUS_PAGE = "LOAD_STATUS_PAGE";
export const CHANGE_ROW = "CHANGE_ROW";
export const CREATE_ENVELOPE = "CREATE_ENVELOPE";
export const RESPONSE = "RESPONSE";
export const DISABLE = "DISABLE";
export const QUERY_CUSTOMERS = "QUERY_CUSTOMERS";
export const CANCEL_STATUS = "CANCEL_STATUS";
export const SHOW_INFO = "SHOW_INFO";
export const DELIVERY_METHOD = "DELIVERY_METHOD";
export const NEXT = "NEXT";
export const LANGUAGE = "LANGUAGE";
export const SUBMIT_PREFILL = "SUBMIT_PREFILL";
export const LOAD_INFO = "LOAD_INFO";
export const STORE_URL = "STORE_URL";
export const DISMISS_WARNING = "DISMISS_WARNING";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SESSION_INFO_CIAM = "SESSION_INFO_CIAM";
export const REFRESH_SESSION_CIAM = "REFRESH_SESSION_CIAM";
export const REFRESH_SESSION_CIAM_PROGRESS = "REFRESH_SESSION_CIAM_PROGRESS";
export const LOGOUT_SESSION_PROGRESS = "LOGOUT_SESSION_PROGRESS";
