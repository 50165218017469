/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Provider } from "react-redux";
import { MuxProvider, THEMES } from "@manulife/mux";
import Routes from "./components/Routes";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <MuxProvider themeProvider={{ theme: THEMES.canadianTheme }}>
        <Routes />
      </MuxProvider>
    </Provider>
  );
}

export default App;
