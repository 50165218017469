/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DataTableExperimental,
  TextInput,
  Button,
  H1,
  H4,
  P,
  Tooltip
} from "@manulife/mux";
import { Link } from "react-router-dom";
import { submitPrefill, getRoles } from "../actions/signerTableActions";
import {
  populateSignerRows,
  populateCopyRows,
  validation
} from "../methods/signersPageMethods";
import { storeLanguage } from "../actions/formSelectActions";
import localizationObject from "../localization";

import "../App.css";

function SignerTable() {
  const dispatch = useDispatch();
  const boundSubmitPrefill = (prefillSigners, policyNumber) =>
    dispatch(submitPrefill(prefillSigners, policyNumber));
  const boundGetRoles = (formNumber, language) => {
    dispatch(getRoles(formNumber, language));
  };
  // const boundStoreLanguage = strings => dispatch(storeLanguage(strings));
  const roles = useSelector(state => state.roles.signerRoles);
  const DEFAULT_NUMBER_OF_SIGNERS = (roles && roles.length) || 10;
  const DEFAULT_NUMBER_OF_SIGNERS_COPY = 10;
  const advisorPrefill = useSelector(state => state.roles.advisorPrefill);
  const carbonCopies = useSelector(state => state.roles.carbonCopies);
  const [localization, setLocalization] = useState({});
  const language = useSelector(state => state.pageLoad.language);
  const advisorSigning = useSelector(state => state.envelope.advisorSigning);

  const defaultAccessCode = useSelector(state => state.pageLoad.accessCode);
  const [accessCode, setAccessCode] = useState(defaultAccessCode.toString());

  // Signers Table
  const [rows, setRows] = useState([]);
  const [partyFirstNames, setPartyFirstNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [partyLastNames, setPartyLastNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [emails, setEmails] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [signerToggles, setSignerToggles] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [toggleStates, setToggleStates] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );

  // Carbon Copy Table
  const [copyRows, setCopyRows] = useState([]);
  const [copyFirstNames, setCopyFirstNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );
  const [copyLastNames, setCopyLastNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );
  const [copyEmails, setCopyEmails] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );

  const [error, setError] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const tempPageLoadState = useSelector(state => state.pageLoad);
  const formInfo = useSelector(state => state.pageLoad.routeState);

  useEffect(() => {
    if (language.length > 0) {
      setLocalization(localizationObject[language]);
    }
  }, [language]);

  useEffect(() => {
    if (roles && roles.length !== 0 && emails.length !== roles.length) {
      setEmails(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setPartyFirstNames(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setPartyLastNames(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setSignerToggles(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setToggleStates(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
    }
  }, [roles]);

  useEffect(() => {
    boundGetRoles(formInfo.formnumber, formInfo.formLanguage);
  }, []);

  useEffect(() => {
    const tempRows = populateSignerRows(
      roles,
      partyFirstNames,
      partyLastNames,
      emails,
      setPartyFirstNames,
      setPartyLastNames,
      setEmails,
      signerToggles,
      toggleStates,
      setSignerToggles,
      setToggleStates,
      localization
    );

    const tempCopyRows = populateCopyRows(
      carbonCopies,
      copyFirstNames,
      copyLastNames,
      copyEmails,
      setCopyFirstNames,
      setCopyLastNames,
      setCopyEmails,
      localization
    );
    setRows(tempRows);
    setCopyRows(tempCopyRows);
  }, [
    emails,
    localization,
    partyFirstNames,
    partyLastNames,
    copyEmails,
    copyFirstNames,
    copyLastNames,
    roles,
    roles.length,
    signerToggles,
    toggleStates
  ]);

  const columnData = useMemo(
    () => [
      {
        header: <H4>{localization.RoleLabel}</H4>,
        accessorKey: "role"
      },
      {
        header: <H4>{localization.FirstName}</H4>,
        accessorKey: "partyFirstName"
      },
      {
        header: <H4>{localization.LastName}</H4>,
        accessorKey: "partyLastName"
      },
      {
        header: <H4>{localization.EmailLabel}</H4>,
        accessorKey: "email"
      },
      {
        header: <H4>{localization.SigningMethodLabel}</H4>,
        accessorKey: "signMethod"
      }
    ],
    [roles]
  );
  const columnDataCopy = useMemo(
    () => [
      {
        header: <H4>{localization.RoleLabel}</H4>,
        accessorKey: "role"
      },
      {
        header: <H4>{localization.FirstName}</H4>,
        accessorKey: "copyFirstName"
      },
      {
        header: <H4>{localization.LastName}</H4>,
        accessorKey: "copyLastName"
      },
      {
        header: <H4>{localization.EmailLabel}</H4>,
        accessorKey: "copyEmail"
      }
    ],
    [roles]
  );

  return localization.Signers ? (
    <div className="page">
      <div className="padding">
        <div tabIndex="0">
          <H1>
            {localization.Signers
              ? localization.Signers
              : "List everyone who needs to sign"}
          </H1>
        </div>
        <P>{localization.SigningInstructions1}</P>
        <P>{localization.SigningInstructions2}</P>
        <P>{localization.SigningInstructions3}</P>
        <P>
          <strong>{localization.SigningReminder}</strong>
          {localization.SigningInstructions4}
        </P>
        <label className="m1" htmlFor="Policy Number">
          {localization.PolicyNumber}
          <Tooltip
            title={localization.PolicyNumberTooltip}
            variant="secondary"
            ariaLabel={localization.PolicyNumberTooltip}
            customStyle={{
              rootStyle: {
                marginLeft: "3%",
                marginBottom: "3%"
              }
            }}
          />
          <br />
          <TextInput
            placeholder={localization.PolicyNumber}
            ariaLabel={localization.PolicyNumber}
            onChange={e => {
              setPolicyNumber(e);
            }}
            value={policyNumber}
            mask="***************"
            forceUpper
            customStyle={{
              rootStyle: {
                maxWidth: "70%"
              }
            }}
          />
        </label>
        <br />
        <label className="m1" htmlFor="Email Access code">
          {localization.EmailAccessCode}
          <Tooltip
            title={localization.EmailAccessCodeTooltip}
            variant="secondary"
            ariaLabel={localization.EmailAccessCodeTooltip}
            customStyle={{
              rootStyle: {
                marginLeft: "3%",
                marginBottom: "3%"
              }
            }}
          />
          <br />
          <TextInput
            placeholder={accessCode}
            ariaLabel={localization.EmailAccessCode}
            maxLength={10}
            onChange={e => {
              setAccessCode(e);
            }}
            value={accessCode}
            customStyle={{
              rootStyle: {
                maxWidth: "70%"
              }
            }}
          />
        </label>
        <div aria-live="assertive">
          <P color="red" data-testid="error">
            {error}
          </P>
        </div>
      </div>
      <div className="table">
        <DataTableExperimental
          columns={columnData}
          data={rows}
          emptyTableMessage={localization.NoRowsText}
        />
      </div>
      <div className="padding">
        <P>{localization.CarbonCopy}</P>
      </div>
      <div className="table">
        <DataTableExperimental
          columns={columnDataCopy}
          id="copyTable"
          data={copyRows}
          emptyTableMessage={localization.NoRowsText}
        />
        <div className="center">
          <Link
            to="/Confirmation"
            onClick={e =>
              validation(
                e,
                accessCode,
                emails,
                partyFirstNames,
                partyLastNames,
                policyNumber,
                copyEmails,
                copyFirstNames,
                copyLastNames,
                setError,
                localization,
                advisorPrefill,
                tempPageLoadState,
                signerToggles,
                carbonCopies,
                roles,
                boundSubmitPrefill,
                advisorSigning
              )
            }
          >
            <Button id="submit" ariaLabel={localization.Next}>
              {localization.Next}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}

export default SignerTable;
