import {
  CREATE_ENVELOPE,
  SHOW_INFO,
  NEXT,
  SUBMIT_PREFILL,
  STORE_URL,
  RESPONSE
} from "../actions/types";

const initialState = {
  userCounter: 1,
  nextSignerURL: "",
  prefillSigners: [],
  policyNumber: "",
  passed: false,
  docuSignURL: "",
  signers: [],
  response: "",
  disabled: false,
  envelopeId: "",
  prefill: true
};

const envelopeReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case NEXT:
      return {
        ...state,
        userCounter: action.userCounter,
        nextSignerURL: action.nextSignerURL
      };
    case SHOW_INFO:
      return {
        ...state,
        signers: action.arrSigners
      };

    case CREATE_ENVELOPE:
      return {
        ...state,
        response: action.response,
        disabled: action.disabled,
        passed: action.passed,
        envelopeId: action.envelopeId,
        preFill: action.preFill
      };
    case STORE_URL:
      return {
        ...state,
        docuSignURL: action.docuSignURL
      };

    case SUBMIT_PREFILL:
      return {
        ...state,
        prefillSigners: action.prefillSigners,
        policyNumber: action.policyNumber
      };

    case RESPONSE:
      return {
        ...state,
        response: action.response,
        sendDisabled: action.sendDisabled,
        disabled: action.disabled,
        passed: action.passed,
        signers: []
      };

    default:
      return state;
  }
};

export default envelopeReducer;
