import { QUERY_CUSTOMERS, CANCEL_STATUS } from "../actions/types";

const initialState = {};

const statusReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case CANCEL_STATUS:
      return {
        ...state,
        Row: [],
        signers: []
      };
    case QUERY_CUSTOMERS:
      return {
        ...state,
        Row: action.arrRow
      };

    default:
      return state;
  }
};

export default statusReducer;
