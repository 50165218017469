/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
import { render } from "react-dom";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import React, { Component } from "react";
import "./EnvelopeStatus.css";
import "./SendEnvelope.css";
import { Button, DatePicker, Tooltip } from "@manulife/mux";
import { Element, scroller } from "react-scroll";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  queryCustomers,
  cancelStatus,
  showInfo
} from "./actions/envelopeStatusActions";

const redirectURL = window.REACT_APP_CIAMBFF_URL;

class EnvelopeStatus extends Component {
  constructor(props) {
    super(props);

    const dateSub = moment().subtract(30, 'day');
    const dateSentVal = dateSub.format("YYYY-MM-DD");

    this.state = {
      Response: "",
      RepsourceID: "",
      language: "",
      visible: true,
      strings: [],
      Row: [],
      signers: [],
      dateValue: dateSentVal,
      ValidateDate: false
    };
    this.changePolicy = this.changePolicy.bind(this);
    this.changeSigningPartyName = this.changeSigningPartyName.bind(this);
  }

  datepicker(newValue) {
    if (Object.prototype.toString.call(this.state.dateValue) === "[object String]" && this.state.dateValue === "") {
      this.setState({
        dateValue: '  ',
        ValidateDate: false
      });
    }
    else {
      this.setState({
        dateValue: newValue,
        ValidateDate: false
      });
    }
  }

  dateInvalid() {
    this.setState({
      ValidateDate: true
    })

  }

  changeSigningPartyName(e) {
    if (e.target.value) {
      this.setState({
        [e.target.name]: e.target.value
      });
    } else {
      this.setState({
        [e.target.name]: undefined
      });
    }
  }


  changePolicy(e) {
    if (e.target.value) {
      this.setState({
        [e.target.name]: e.target.value
      });
    } else {
      this.setState({
        [e.target.name]: undefined
      });
    }
  }

  showInfo(e, dataRow) {
    const data = dataRow.row._original;
    const self = this;
    self.setState({
      visible: false,
      signers: []
    });

    const { token } = this.props.routeState;
    this.props.showInfo(data, this.props.strings);

    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  onCancel() {
    document.getElementById("Form").reset();
    this.props.cancelStatus();
    this.setState({
      visible: true
    });
    this.setState({
      PolicyNumber: "",
      SigningPartyName: "",
    });
    this.setState({
      PolicyNumber: undefined,
      SigningPartyName: undefined,
      dateValue: '  ',
      ValidateDate: false
    });
  }

  // validdate(e) {
  //   // const pattern =
  //   //   "^(19|20)\\d\\d([- /.])(0[1-9]|1[012])\\2(0[1-9]|[12][0-9]|3[01])$";

  //   // const reg = new RegExp(pattern);
  //   // if (!e.target.value.match(reg)) {
  //   //   this.setState({
  //   //     dateError: this.props.strings.DateError
  //   //   });
  //   // } else {
  //   //   this.setState({
  //   //     dateError: "ss"
  //   //   });
  //   // }
  //   if (!e.value) {
  //     this.setState({
  //       dateError: this.props.strings.DateError
  //     });
  //   } else {
  //     this.setState({
  //       dateError: "ss"
  //     });
  //   }
  // }

  onSubmit(e) {
    const self = this;
    self.setState({
      signers: []
    });
    this.props.queryCustomers(
      this.props.params.RepsourceID,
      this.state.PolicyNumber,
      this.state.SigningPartyName,
      this.state.dateValue,
      this.props.strings
    );
    this.setState((prevState) => ({
      PolicyNumber: prevState.PolicyNumber,
      SigningPartyName: prevState.SigningPartyName,
      dateValue: prevState.dateValue,
    }));
  }

  async componentDidMount() {
    const { token } = this.props.routeState;
    if (!this.props.ciamSession) {
      if (this.props.location === undefined) {
        window.location.href = redirectURL;
      }

      if (token === undefined || token === "") {
        window.location.href = redirectURL;
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.strings !== undefined ? (
          <div>
            <div className="FormWrapper">
              <h1 className="title">
                {this.props.strings.EnvelopeStatusTitle}
              </h1>
              <div className="WrapperWidth">
                <form id="Form">
                  <div className="Rtable">
                    <div className="Rtable-cell Rtable-cell-header Rtable-cell--2of4">
                      <h4 color="white">{this.props.strings.PolicyNumber}</h4>
                    </div>
                    <div className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4 ">
                      <input
                        type="text"
                        className="inputBox"
                        id="PolicyNumber"
                        maxLength="15"
                        placeholder={this.props.strings.Optional}
                        ariaLabel={`${this.props.strings.PolicyNumber} ${this.props.strings.Optional}`}
                        value={this.state.PolicyNumber}
                        name="PolicyNumber"
                        onInput={e => this.changePolicy(e)}
                      />
                    </div>
                    <div className="Rtable-cell Rtable-cell-header Rtable-cell--2of4 ">
                      <h4 color="white">{this.props.strings.LastName}</h4>
                    </div>
                    <div className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4 ">
                      <input
                        type="text"
                        className="inputBox"
                        id="SigningPartyName"
                        value={this.state.SigningPartyName}
                        ariaLabel={`${this.props.strings.LastName} ${this.props.strings.Optional}`}
                        placeholder={this.props.strings.Optional}
                        name="SigningPartyName"
                        onInput={e => this.changeSigningPartyName(e)}
                      />
                    </div>
                    <div
                      className="Rtable-cell Rtable-cell-dark Rtable-cell--4of4"
                      style={{ height: "165px", overflow: "visible" }}
                    >
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        labelFormat={this.props.strings.DateFormatISO}
                        label={this.props.strings.DateRange}
                        value={this.state.dateValue}
                        required={false}
                        dateInvalidError={`${this.props.strings.DateError  } ${  this.props.strings.DateFormat}`}
                        dateRangeError={this.props.strings.DateTracking}
                        onError={(e) => { this.dateInvalid(e) }}
                        placeholder={this.props.strings.Optional}
                        tooltip={<Tooltip content={this.props.strings.DateTracking} />}
                        disabledDays={{
                          before: new Date(
                            new Date().setDate(new Date().getDate() - 30)
                          ),
                          after: new Date()
                        }}
                        onChange={newValue => this.datepicker(newValue)}
                        customStyle={{
                          rootStyle: {
                            maxWidth: "2000px"
                          }
                        }}
                      />
                    </div>
                    {/* <div className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4">
                      <input
                        type="text"
                        className="inputBox"
                        id="DateRange"
                        value={this.state.DateRange}
                        placeholder={this.props.strings.Optional}
                        ariaLabel={`${this.props.strings.DateRange} ${this.props.strings.Optional}`}
                        onBlur={e => this.validdate(e)}
                        name="DateRange"
                        onChange={e => this.change(e)}
                      />
                    </div> */}
                    {/* <div className="Rtable-cell Rtable-cell-dark Rtable-cell--2of4">
                      <DatePicker
                        dateFormat="YYYY/MM/DD"
                        label="Select Date"
                        value={this.state.date}
                        placeholder={this.props.strings.Optional}
                        disabledDays={{
                          before: new Date(
                            new Date().setDate(new Date().getDate() - 30)
                          ),
                          after: new Date()
                        }}
                        onChange={newValue => this.datepicker(newValue)}
                      />
                      <TextInput
                        icon={(
                          <ActionButton
                            icon={<CDS.Date />}
                            onClick={() => this.openModal()}
                          />
                        )}
                        isIconClickable
                        disabled
                        placeholder={this.props.strings.Optional}
                        customStyle={{
                          rootStyle: {
                            maxWidth: "175px"
                          },
                          inputStyle: {
                            lineHeight: "0.5px"
                          }
                        }}
                      />
                    </div> */}
                  </div>
                </form>
                <div className="Buttons">
                  <Button
                    disabled={this.state.ValidateDate}
                    className="ButtonSubmit"
                    onClick={e => this.onSubmit(e)}
                  >
                    {this.props.strings.Search}
                  </Button>
                  <Button
                    className="ButtonDiscard"
                    onClick={() => this.onCancel()}
                  >
                    {this.props.strings.Discard}
                  </Button>
                </div>
              </div>
              <div className="mt-2">
                <ReactTable
                  data={this.props.Row}
                  columns={[
                    {
                      Header: this.props.strings.DateSent,
                      accessor: "DateSent", // String-based value accessors!
                      Cell: row => (
                        <span>
                          {moment.utc(row.value).format("MM/DD/YYYY")}
                        </span>
                      ),
                      sort: "desc",
                      style: { whiteSpace: "unset" }
                    },
                    {
                      Header: this.props.strings.Owner,
                      accessor: "ClientName",
                      sort: "asc",
                      style: { whiteSpace: "unset" }
                    },
                    {
                      Header: this.props.strings.PolicyNumber,
                      accessor: "policyNumber", // Required because our accessor is not a string
                      sort: "asc",
                      style: { whiteSpace: "unset" }
                    },
                    {
                      Header: this.props.strings.GetAdditionalInfo,
                      Cell: row => (
                        <Button onClick={e => this.showInfo(e, row)}>
                          + Click here
                        </Button>
                      ),
                      style: { whiteSpace: "unset" }
                    }
                  ]}
                  defaultPageSize={10}
                  minRows={1}
                  defaultSorted={[
                    {
                      id: "DateSent",
                      desc: true
                    }
                  ]}
                  className="-striped -highlight"
                />
              </div>
              <div
                className={this.state.visible ? "hidden" : "mt-3"}
                margin-top="1%"
              >
                <Element name="scroll-to-element" className="element" />
                <ReactTable
                  data={this.props.signers}
                  columns={[
                    {
                      Header: this.props.strings.DateSent,
                      accessor: "date",
                      Cell: row => (
                        <span>
                          {moment.utc(row.value).format("MM/DD/YYYY")}
                        </span>
                      ),
                      style: { whiteSpace: "unset" },
                      width: 150
                    },
                    {
                      Header: this.props.strings.FormNumber,
                      accessor: "docName",
                      style: { whiteSpace: "unset" }
                    },
                    {
                      Header: this.props.strings.SigningParty,
                      accessor: "signersName",
                      style: { whiteSpace: "unset" }
                    },
                    {
                      Header: this.props.strings.EmailAddress,
                      accessor: "email",
                      style: { whiteSpace: "unset" },
                      width: 350
                    },
                    {
                      Header: this.props.strings.AccessCode,
                      accessor: "password",
                      style: { whiteSpace: "unset" },
                      width: 150
                    },
                    {
                      Header: this.props.strings.Status,
                      accessor: "status",
                      style: { whiteSpace: "unset" }
                    }
                  ]}
                  minRows={0}
                  showPagination={false}
                  className="-striped -highlight"
                />
                <div>
                  <label className="col-md-12 control-label mt-1 mb-1">
                    {this.props.strings.FooterDesc1}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>"Loading..."</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  strings: state.pageLoad.strings,
  checked: state.pageLoad.checked,
  params: state.pageLoad.params,
  Row: state.status.Row,
  signers: state.envelope.signers,
  routeState: state.pageLoad.routeState,
  ciamSession: state.pageLoad.ciamSession
});

EnvelopeStatus.propTypes = {
  queryCustomers: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  cancelStatus: PropTypes.func.isRequired,
  Row: PropTypes.array.isRequired,
  signers: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  ciamSession: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, {
  queryCustomers,
  cancelStatus,
  showInfo
})(EnvelopeStatus);
