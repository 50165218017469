/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import axios from "axios";
import moment from "moment";
import { RESPONSE, QUERY_CUSTOMERS, CANCEL_STATUS, SHOW_INFO } from "./types";
import methods from "../StatusMethods";

const searchRoute = window.REACT_APP_SEARCHDATA_ROUTE;
const reciptListRoute = window.REACT_APP_RECIPIENTS_ROUTE;
const docListRoute = window.REACT_APP_DOCLIST_ROUTE;
const envelopeStatusRoute = window.REACT_APP_ENVELOPESTATUS_ROUTE;

const getDocumentName = (data, strings) => {
  const DOC_DISPLAY_DEFAULT = "inline";
  return axios
    .post(docListRoute, {
      data: {
        accountId: data.DocuSignAccountNumber,
        envelopeId: data.EnvelopeID
      }
    })
    .then((response) => {
      return response.data.envelopeDocuments.filter(
        (doc) => doc.display === DOC_DISPLAY_DEFAULT
      )[0].name;
    })
    .catch(() => {
      throw new Error(strings.ServerError);
    });
};

const getDocumentData = (data, docName, strings) => {
  return axios
    .post(envelopeStatusRoute, {
      data: {
        accountId: data.DocuSignAccountNumber,
        envelopeId: data.EnvelopeID
      }
    })
    .then((response) => {
      return {
        status: response.data.status,
        docName,
        sentDate: response.data.sentDateTime
      };
    })
    .catch(() => {
      throw new Error(strings.ServerError);
    });
};

const getSigners = (data, documentData, docName, strings) => {
  return axios
    .post(reciptListRoute, {
      data: {
        accountId: data.DocuSignAccountNumber,
        envelopeId: data.EnvelopeID
      }
    })
    .then((response) => {
      const arrSigners = [];
      response.data.map((item) => {
        let authenticationStatus;
        if (item.recipientAuthenticationStatus === undefined) {
          authenticationStatus = "";
        } else {
          authenticationStatus =
            item.recipientAuthenticationStatus.accessCodeResult.status;
        }
        const status = methods.translateStatus(
          documentData.status,
          item.status,
          authenticationStatus,
          strings
        );
        arrSigners.push({
          date: moment(documentData.sentDate).format("M/D/YYYY"),
          signersName: item.name,
          role: item.roleName,
          email: item.email,
          password: item.accessCode,
          status,
          docName
        });
      });
      return arrSigners;
    })
    .catch(() => {
      throw new Error(strings.ServerError);
    });
};

export const showInfo = (data, strings) => async (dispatch) => {
  try {
    const docName = await getDocumentName(data, strings);
    const docData = await getDocumentData(data, docName, strings);
    const arrSigners = await getSigners(data, docData, docName, strings);
    dispatch({
      type: SHOW_INFO,
      arrSigners
    });
  } catch (err) {
    dispatch({
      type: RESPONSE,
      response: strings.ServerError,
      sendDisabled: true,
      disabled: true,
      passed: false
    });
  }
};

export const cancelStatus = () => {
  return {
    type: CANCEL_STATUS
  };
};

export const queryCustomers = (
  RepsourceID,
  PolicyNumber,
  SigningPartyName,
  DateRange,
  Strings
) => (dispatch) => {
  let dateSentVal = DateRange;
  if (Object.prototype.toString.call(dateSentVal) === "[object String]" && dateSentVal === '  ') { // IF dateRange is blank then let's default it to 30 days ago
    const dateSub = moment();
    dateSub.subtract(30, 'day');
    dateSentVal = dateSub.format("YYYY-MM-DD")
  }
  return axios
    .post(searchRoute, {
      data: {
        repsourceID: RepsourceID,
        policyNumber: PolicyNumber,
        clientLastName: SigningPartyName,
        date: dateSentVal
      }
    })
    .then((response) => {
      const arrRow = [];
      // eslint-disable-next-line array-callback-return
      response.data.recordset.map((item) => {
        arrRow.push({
          EnvelopeID: item.EnvelopeID,
          DocuSignAccountNumber: item.DocuSignAccountNumber,
          ClientName: item.ClientName,
          DateSent: moment(item.DateSent).format("M/D/YYYY"),
          policyNumber: item.PolicyNumber
        });
      });

      dispatch({
        type: QUERY_CUSTOMERS,
        arrRow
      });
    })
    .catch(() => {
      dispatch({
        type: RESPONSE,
        response: Strings.ServerError,
        sendDisabled: true,
        disabled: true,
        passed: false
      });
    });
};
