import {
  LOAD_PAGE,
  LOAD_STATUS_PAGE,
  LOAD_INFO,
  DISMISS_WARNING,
  SET_LANGUAGE,
  SESSION_INFO_CIAM,
  REFRESH_SESSION_CIAM,
  REFRESH_SESSION_CIAM_PROGRESS,
  LOGOUT_SESSION_PROGRESS
} from "../actions/types";

const initialState = {
  RepsourceID: "",
  repsourceEmail: "",
  repsourceFirstname: "",
  repsourceLastname: "",
  English: true,
  strings: {},
  accessCode: "",
  language: "",
  routeState: {},
  dismissWarning: false,
  ciamSession: false,
  params: {},
  refreshedCIAMSessionTime: Date.now(),
  ciamREFRESHInProgress: false,
  logoutInProgress: false
};

const pageLoadReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case LOAD_INFO:
      return {
        ...state,
        routeState: action.routeState
      };
    case LOAD_STATUS_PAGE:
      return {
        ...state,
        checked: action.data.checked,
        strings: action.data.strings,
        params: action.data.params
      };

    case DISMISS_WARNING:
      return {
        ...state,
        dismissWarning: action.dismissWarning
      };

    case SET_LANGUAGE:
      return {
        ...state,
        strings: action.strings
      };

    case LOAD_PAGE: {
      return {
        ...state,
        RepsourceID: action.data.Params.RepsourceID,
        repsourceEmail: action.data.Params.email,
        repsourceFirstname: action.data.Params.firstname,
        repsourceLastname: action.data.Params.lastname,
        repsourceRole: action.data.Params.title,
        English: action.data.English,
        strings: action.data.Strings,
        accessCode: action.data.accessCode,
        language: action.data.Params.preferredlanguage
      };
    }
    case SESSION_INFO_CIAM: {
      let params = {};
      if (!action.sessionInfo.formnumber) {
        params = {
          RepsourceID: action.sessionInfo.surrogateUserIDs,
          preferredlanguage: action.sessionInfo.preferredlanguage,
          firstname: action.sessionInfo.firstName,
          lastname: action.sessionInfo.lastName,
          title: action.sessionInfo.title,
          email: action.sessionInfo.email
        };
      }
      return {
        ...state,
        RepsourceID: action.sessionInfo.surrogateUserIDs,
        repsourceEmail: action.sessionInfo.email,
        repsourceFirstname: action.sessionInfo.firstName,
        repsourceLastname: action.sessionInfo.lastName,
        repsourceRole: action.sessionInfo.title,
        language: action.sessionInfo.preferredlanguage,
        routeState: action.sessionInfo.routeState,
        accessCode: action.sessionInfo.accessCode,
        params,
        strings: action.sessionInfo.strings,
        ciamSession: true
      };
    }
    case REFRESH_SESSION_CIAM: {
      return {
        ...state,
        refreshedCIAMSessionTime: action.data,
        ciamREFRESHInProgress: false
      };
    }
    case REFRESH_SESSION_CIAM_PROGRESS: {
      return {
        ...state,
        ciamREFRESHInProgress: true
      };
    }
    case LOGOUT_SESSION_PROGRESS: {
      return {
        ...state,
        logoutInProgress: true
      };
    }

    default:
      return state;
  }
};

export default pageLoadReducer;
