import axios from "axios";
import { GET_ROLES, SUBMIT_PREFILL } from "./types";

const getRowsRoute = window.REACT_APP_GETROWS_ROUTE;

export const getRoles = (formnumber, language) => dispatch => {
  return axios
    .post(getRowsRoute, {
      data: {
        FormNumber: formnumber,
        Language: language
      }
    })
    .then(res => {
      dispatch({
        type: GET_ROLES,
        data: res.data
      });
    })
    .catch(error => {
      return error;
    });
};

export const submitPrefill = (prefillSigners, policyNumber) => {
  return {
    type: SUBMIT_PREFILL,
    prefillSigners,
    policyNumber
  };
};
