const processEnv = process.env;

export default function envLoader() {
  if (typeof window.REACT_APP_ENV === "undefined") {
    window.REACT_APP_DOCUSIGN_ROUTE = processEnv.REACT_APP_DOCUSIGN_ROUTE;
    window.REACT_APP_GETROWS_ROUTE = processEnv.REACT_APP_GETROWS_ROUTE;
    window.REACT_APP_INSERTDATA_ROUTE = processEnv.REACT_APP_INSERTDATA_ROUTE;
    window.REACT_APP_SEARCHDATA_ROUTE = processEnv.REACT_APP_SEARCHDATA_ROUTE;
    window.REACT_APP_DOCLIST_ROUTE = processEnv.REACT_APP_DOCLIST_ROUTE;
    window.REACT_APP_GETSAML_URL = processEnv.REACT_APP_GETSAML_URL;
    window.REACT_APP_RECIPIENTS_ROUTE = processEnv.REACT_APP_RECIPIENTS_ROUTE;
    window.REACT_APP_REDIRECT = processEnv.REACT_APP_CIAMBFF_URL;
    window.REACT_APP_ENVELOPESTATUS_ROUTE =
      processEnv.REACT_APP_ENVELOPESTATUS_ROUTE;
    window.REACT_APP_PREFILL_ROUTE = processEnv.REACT_APP_PREFILL_ROUTE;
    window.REACT_APP_SELECT_ROUTE = processEnv.REACT_APP_SELECT_ROUTE;
    window.REACT_APP_DOCUSIGN_IMAGE_ROUTE =
      processEnv.REACT_APP_DOCUSIGN_IMAGE_ROUTE;
    window.REACT_APP_ENV = processEnv.REACT_APP_ENV;
    window.REACT_APP_CIAMBFF_URL = processEnv.REACT_APP_CIAMBFF_URL;
    window.REACT_APP_RETURN_ROUTE = processEnv.REACT_APP_RETURN_ROUTE;
    window.REACT_APP_PUBLIC_URL = processEnv.REACT_APP_PUBLIC_URL;
    window.REACT_APP_SESSION_EXPIRATION_MINUTES =
      processEnv.REACT_APP_SESSION_EXPIRATION_MINUTES;
    window.REACT_APP_SESSION_BEFORE_EXPIRATION_MINUTES =
      processEnv.REACT_APP_SESSION_BEFORE_EXPIRATION_MINUTES;
    window.REACT_APP_SESSION_REFRESH_COOLDOWN_SEC =
      processEnv.REACT_APP_SESSION_REFRESH_COOLDOWN_SEC;
  }
}
