/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
import axios from "axios";
import envLoader from "../Utils/envUtils";

// TODO: Unit Test Loader, may get rid of it w. proper testing logic
if (!window.REACT_APP_GETSAML_URL) {
  envLoader();
}

const docusignRoute = window.REACT_APP_DOCUSIGN_ROUTE;
const docuSignImageRoute = window.REACT_APP_DOCUSIGN_IMAGE_ROUTE;
const getDocRoute = window.REACT_APP_PREFILL_ROUTE;
const docListRoute = window.REACT_APP_DOCLIST_ROUTE;
const envelopeStatusRoute = window.REACT_APP_ENVELOPESTATUS_ROUTE;
const returnUrl = window.REACT_APP_RETURN_ROUTE;
const selectRoute = window.REACT_APP_SELECT_ROUTE;

// eslint-disable-next-line no-var
export async function generatePass() {
  const cryptoArray = new Uint32Array(1);
  window.crypto.getRandomValues(cryptoArray);

  return cryptoArray[0];
}

export async function getForms() {
  return axios
    .post(selectRoute, {
      data: {}
    })
    .then(forms => {
      return forms.data.recordset;
    })
    .catch(function(error) {
      throw new Error(error);
    });
}

// retrieve the id from docusign
export const generateEnvelope = (
  submittedAdvisors,
  templateID,
  accountID,
  repsourceID,
  policyNumber,
  ownerFirstName,
  ownerLastName,
  repsourceFirstName,
  repsourceLastName,
  repsourceEmail,
  repsourceRole,
  FormNumber,
  FormName,
  token,
  isCiamSession
) => {
  return axios
    .post(docusignRoute, {
      data: {
        custom: [
          {
            name: "RepsourceUserID",
            show: "true",
            value: repsourceID
          },
          {
            name: "policyNumber",
            show: "true",
            value: policyNumber
          },
          {
            name: "FirstName",
            show: "true",
            value: ownerFirstName
          },
          {
            name: "LastName",
            show: "true",
            value: ownerLastName
          },
          {
            name: "RepsourceUserName",
            show: "true",
            value: `${repsourceFirstName} ${repsourceLastName}`
          },
          {
            name: "RepsourceUserEmail",
            show: "true",
            value: repsourceEmail
          },
          {
            name: "RepsourceUserRole",
            show: "true",
            value: repsourceRole
          },
          {
            name: "FormNumber",
            show: "true",
            value: FormNumber
          },
          {
            name: "FormName",
            show: "true",
            value: FormName
          }
        ],
        advisers: submittedAdvisors,
        templateId: templateID,
        accountId: accountID,
        status: "sent"
      },
      token,
      isCiamSession
    })
    .then(response => {
      const { envelopeId } = JSON.parse(response.data);
      return envelopeId;
    })
    .catch(error => {
      return error;
    });
};

// actually pop up the form in another window (for prefill?)
export const getDocumentURL = (envelopeID, advisor, accountID) => {
  return axios
    .post(getDocRoute, {
      data: {
        accountId: accountID,
        authenticationMethod: "email",
        clientUserId: "9999",
        email: advisor.email,
        envelopeId: envelopeID,
        userName: advisor.name,
        returnUrl
      }
    })
    .then(response => {
      return response.data.url;
    })
    .catch(error => {
      return error;
    });
};

// indicate whether or not the user wants to prefill (to be deprecated?)
export const populatePreFill = (envelopeID, preFill) => {
  if (preFill) {
    return {
      envelopeId: envelopeID,
      flag: true
    };
  }
  return {
    envelopeId: envelopeID,
    flag: false
  };
};

export const getDocumentName = data => {
  return axios
    .post(docListRoute, {
      data: {
        accountId: data.DocuSignAccountNumber,
        envelopeId: data.EnvelopeID
      }
    })
    .then(response => {
      return response.data.envelopeDocuments[0].name;
    })
    .catch(error => {
      return error;
    });
};

export const getDocumentStatus = (data, docName) => {
  return axios
    .post(envelopeStatusRoute, {
      data: {
        accountId: data.DocuSignAccountNumber,
        envelopeId: data.EnvelopeID
      }
    })
    .then(response => {
      return {
        status: response.data.status,
        docName
      };
    })
    .catch(error => {
      return error;
    });
};

export const getDocImage = (accountID, templateID) => {
  return axios
    .post(docuSignImageRoute, {
      data: {
        accountId: accountID,
        templateId: templateID
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const setAxiosHeaders = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common.Route = "MG";
};
