/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
module.exports = {
  translateStatus(EStatus, RStatus, AStatus, strings) {
    const statuses = [
      {
        EStatus: "sent",
        RStatus: "sent",
        AStatus: "",
        Status: strings.SignaturePending
      },
      {
        EStatus: "sent",
        RStatus: "created",
        AStatus: "",
        Status: strings.SignaturePending
      },
      {
        EStatus: "deliverd",
        RStatus: "deliverd",
        AStatus: "",
        Status: strings.ClientViewed
      },
      {
        EStatus: "delivered",
        RStatus: "delivered",
        AStatus: "",
        Status: strings.ClientViewed
      },
      {
        EStatus: "delivered",
        RStatus: "delivered",
        AStatus: "Passed",
        Status: strings.SignaturePending
      },
      {
        EStatus: "delivered",
        RStatus: "delivered",
        AStatus: "Failed",
        Status: strings.ClientFailed
      },
      {
        EStatus: "delivered",
        RStatus: "completed",
        AStatus: "",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "delivered",
        RStatus: "completed",
        AStatus: "Passed",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "delivered",
        RStatus: "created",
        AStatus: "",
        Status: strings.SignaturePending
      },
      {
        EStatus: "completed",
        RStatus: "completed",
        AStatus: "",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "completed",
        RStatus: "completed",
        AStatus: "Passed",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "declined",
        RStatus: "declined",
        AStatus: "",
        Status: strings.ClientDeclined
      },
      {
        EStatus: "declined",
        RStatus: "declined",
        AStatus: "Passed",
        Status: strings.ClientDeclined
      },
      {
        EStatus: "declined",
        RStatus: "completed",
        AStatus: "",
        Status: strings.ClientDeclined
      },
      {
        EStatus: "declined",
        RStatus: "created",
        AStatus: "",
        Status: strings.ClientDeclined
      },
      {
        EStatus: "voided",
        RStatus: "sent",
        AStatus: "",
        Status: strings.Expired
      },
      {
        EStatus: "voided",
        RStatus: "sent",
        AStatus: "Passed",
        Status: strings.Expired
      },
      {
        EStatus: "sent",
        RStatus: "autoresponded",
        AStatus: "",
        Status: strings.InvalidEmail
      },
      {
        EStatus: "delivered",
        RStatus: "autoresponded",
        AStatus: "",
        Status: strings.InvalidEmail
      },
      {
        EStatus: "sent",
        RStatus: "sent",
        AStatus: "Failed",
        Status: strings.ClientFailed
      },
      {
        EStatus: "sent",
        RStatus: "sent",
        AStatus: "Passed",
        Status: strings.SignaturePending
      },
      {
        EStatus: "sent",
        RStatus: "completed",
        AStatus: "",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "sent",
        RStatus: "completed",
        AStatus: "Passed",
        Status: strings.SignatureReceived
      },
      {
        EStatus: "sent",
        RStatus: "delivered",
        AStatus: "",
        Status: strings.SignaturePending
      },
      {
        EStatus: "sent",
        RStatus: "delivered",
        AStatus: "Passed",
        Status: strings.SignaturePending
      },
      {
        EStatus: "sent",
        RStatus: "delivered",
        AStatus: "Failed",
        Status: strings.ClientFailed
      },
      {
        EStatus: "voided",
        RStatus: "created",
        AStatus: "",
        Status: strings.Expired
      }
    ];

    for (let i = 0; i < statuses.length; i++) {
      if (
        statuses[i].EStatus === EStatus &&
        statuses[i].RStatus === RStatus &&
        statuses[i].AStatus === AStatus
      ) {
        return statuses[i].Status;
      }
    }
  }
};
